// @mui
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// routes
import { paths } from "src/routes/paths";
import { usePathname } from "src/routes/hooks";
import { RouterLink } from "src/routes/components";
// _mock
import { _socials } from "src/_mock";
// components
import Logo from "src/components/logo";
import Iconify from "src/components/iconify";

// ----------------------------------------------------------------------

const LINKS = [
    {
        headline: "Adam Vacations",
        children: [
            { name: "About us", href: paths.about },
            {
                name: "Contact us",
                href: paths.contact,
            },
            // { name: 'FAQs', href: paths.faqs },
        ],
    },
    {
        headline: "Legal",
        children: [
            {
                name: "Terms and Condition",
                href: paths.terms,
            },
            {
                name: "Privacy Policy",
                href: paths.policy,
            },
            {
                name: "Cookies Policy",
                href: paths.cookie,
            },
        ],
    },
    {
        headline: "Contact",
        children: [
            {
                name: "support@adamvacations.com",
                href: "mailto: support@adamvacations.com",
            },
            {
                name: "Get Support",
                href: paths.support,
            },
        ],
    },
];

// ----------------------------------------------------------------------

export default function Footer() {
    const pathname = usePathname();

    const isHome = pathname === "/";

    const mainFooter = (
        <Box
            component="footer"
            sx={{
                position: "relative",
                bgcolor: "background.default",
            }}
        >
            <Divider />

            <Container
                sx={{
                    pt: 10,
                    pb: 5,
                    textAlign: { xs: "center", md: "unset" },
                }}
            >
                <Logo
                    src="/assets/logotz.png"
                    sx={{
                        mb: 5,
                        mx: "auto",
                        width: "auto",
                        maxWidth: { xs: 120, md: 150 },
                    }}
                />

                <section className="grid sm:grid-cols-2 lg:grid-cols-5 gap-4 sm:gap-2 lg:gap-8">
                    <article className="lg:col-span-2">
                        <Typography
                            variant="body2"
                            sx={{
                                maxWidth: { xs: "auto", sm: 320 },
                            }}
                            textAlign={{ xs: "center", sm: "start" }}
                        >
                            Join Adam Vacations in revolutionizing your travel
                            experience with our cutting-edge AI travel software.
                            Effortlessly book flights, secure hotel
                            reservations, and tailor your travel packages to
                            perfection, including car rental services. Harness
                            the power of advanced technology to revolutionize
                            your journey and redefine the way you explore the
                            world with our innovative AI-driven solutions.
                        </Typography>

                        <Stack
                            direction="row"
                            justifyContent={{ xs: "center", md: "flex-start" }}
                            sx={{
                                mt: 3,
                                mb: { xs: 2, md: 0 },
                            }}
                        >
                            {_socials.map((social) => (
                                <a
                                    key={social.name}
                                    href={social.path}
                                    target={social.path}
                                    rel="noopener noreferrer"
                                >
                                    <IconButton
                                        key={social.name}
                                        sx={{
                                            "&:hover": {
                                                bgcolor: alpha(
                                                    social.color,
                                                    0.08,
                                                ),
                                            },
                                        }}
                                    >
                                        <Iconify
                                            color={social.color}
                                            icon={social.icon}
                                        />
                                    </IconButton>
                                </a>
                            ))}
                        </Stack>
                    </article>
                    {LINKS.map((list) => (
                        <article key={list.headline}>
                            <Typography
                                component="div"
                                variant="overline"
                                textAlign="start"
                            >
                                {list.headline}
                            </Typography>
                            <ul className="flex flex-col items-start">
                                {list.children.map((link) => (
                                    <li key={link.name}>
                                        <Link
                                            component={RouterLink}
                                            to={link.href}
                                            color="inherit"
                                            variant="body2"
                                        >
                                            {link.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </article>
                    ))}
                </section>

                <Typography variant="caption" component="div">
                    ©2025 All rights reserved made by Travelandz Team
                    <br />
                    <a
                        href="https://travelandz.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        AI Travel Planner
                    </a>
                    {" | "}
                    <a
                        href="https://travelandz.business"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Travelandz Business
                    </a>
                </Typography>
            </Container>
        </Box>
    );

    return mainFooter;
}
